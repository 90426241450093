<template>
  <!-- 公区报修 -->
  <div class="index">
    <h3 class="required">维修类型</h3>
    <div class="my-picker">
      <my-picker
        :columns="repairTypes"
        :columns-field-names="{
        text: 'describe',
      }"
        @confirm="pickerRepairType"
      />
    </div>

    <h3 class="required">问题描述</h3>
    <van-field
      style="border-radius: 4px; background-color: #f7f7f7"
      v-model="description"
      rows="4"
      type="textarea"
      maxlength="100"
      placeholder="请输入留言"
      show-word-limit
    />

    <h3 class="required">现场照片（最多5张）</h3>
    <!-- 上传图片 -->
    <my-upload @up-loader="uploader" />

    <h3 class="required">是否为返修问题</h3>
    <van-radio-group v-model="is_old" direction="horizontal">
      <van-radio :name="0">新问题</van-radio>
      <van-radio :name="1">旧问题返修</van-radio>
    </van-radio-group>

    <h3 class="required">联系人信息</h3>
    <div style="display: flex; justify-content: space-between">
      <van-field
        style="width: 43%; border-radius: 4px; background-color: #f7f7f7"
        left-icon="contact"
        disabled
        v-model="contact"
      />
      <van-field
        style="width: 53%; border-radius: 4px; background-color: #f7f7f7"
        left-icon="phone-o"
        type="tel"
        maxlength="11"
        disabled
        v-model="mobile"
      />
    </div>
    <!-- 是否加急 -->
    <h3>此次报修是否加急？</h3>
    <div class="switch-box">
      <van-switch v-model="is_urgenth" active-color="#ee0a24" />
      <span :style="{color: is_urgenth ? '#ee0a24' : ''}">{{is_urgenth ? '特急' : '普通'}}</span>
    </div>
    <div style="margin-top: 40px">
      <van-button type="primary" block round @click="onSubmit">立即提交</van-button>
    </div>
  </div>
</template>

<script>
import { getRepairTypesApi, postRepairApi } from '@/api'
import { isMobile } from '@/utils'
import MyPicker from '@/components/picker/index.vue'
import MyUpload from '@/components/upload/index.vue'
export default {
  name: 'Maintain',
  components: {
    MyPicker,
    MyUpload
  },
  data() {
    return {
      is_urgenth: false, // 是否加急
      user_info: {}, // 商户信息
      repairTypes: [], // 维修类型列表
      depart_id: 0, // 维修类型ID
      type: '', // 维修类型
      description: '', // 描述
      is_old: 0, // 问题类型
      contact: '宋非凡', // 联系人
      mobile: '18621867688', // 手机
      images: [],
    }
  },
  created() {
    if (!window.localStorage.getItem('user_info')) return
    this.user_info = JSON.parse(window.localStorage.getItem('user_info'))
    // 获取维修类型数据
    this.getRepairTypesData()
  },
  methods: {
    getRepairTypesData() {
      getRepairTypesApi().then((res) => {
        console.log('维修类型', res)
        if (res.code !== 0) return
        this.repairTypes = res.data
      })
    },
    // 维修类型选择
    pickerRepairType(item) {
      this.depart_id = +item.depart_id
      this.type = item.describe
    },
    // 上传图片
    uploader(images) {
      this.images = images
    },
    // 立即提交
    onSubmit() {
      if (
        !this.depart_id ||
        !this.description ||
        this.images.length <= 0
      )
        return this.$toast('请补全信息！')
      if (!isMobile(this.mobile)) return this.$toast('请输入正确的手机号！')
      const params = {
        access_token: this.user_info.token,
        type: this.type,
        depart_id: this.depart_id,
        description: this.description,
        images: this.images.join(','),
        is_old: this.is_old,
        contact: this.contact,
        mobile: this.mobile,
        is_urgenth: this.is_urgenth
      }
      console.log(params)
      postRepairApi(params).then((res) => {
        if (res.code !== 0) return
        this.$toast({
          type: 'success',
          message: '提交成功！',
          onClose: () => {
            this.$router.push('/repair/helper')
          }
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.index {
  position: relative;
  padding: 60px 40px 80px;
  .index-notice {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  h3 {
    margin-top: 20px;
    padding: 20px 0;
  }
  .switch-box {
    display: flex;
    align-items: center;
    span {
      font-size: 36px;
      padding: 0 10px;
      font-weight: 500;
    }
  }
}
</style>
